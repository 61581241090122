/** Wrapper around localStorage Items */

const _STORE = {
    set: function (key, value){
        window.localStorage.setItem(key, JSON.stringify(value));
    },
    get: function (key){
        return JSON.parse(window.localStorage.getItem(key))
    },
    clean: function (key){
        return window.localStorage.removeItem(key);
    }
}

function store_user(user){
    _STORE.set("__current__", user);
}

function get_user(){
    return _STORE.get("__current__");
}

function clear_user(){
    _STORE.clean("__current__");
}

function store_submissions(sub){
    _STORE.set('__submissions__', sub);
}

function get_submissions(){
    return _STORE.get("__submissions__");
}

function clear_submissions(){
    _STORE.clean("__submissions__");
}

function get_profile(){
    return _STORE.get("__profile__");
}

function store_profile(profile){
    _STORE.set('__profile__', profile);
}

function clear_profile(){
    _STORE.clean("__profile__");
}

function clear_all_data(){
    clear_profile();
    clear_submissions();
    clear_user();
}

export const store = {
    /* User Auth & Base info */
    user: {
        get: get_user,
        set: store_user,
        clear: clear_user
    },
    /* Submission Page Info */
    submissions: {
        get: get_submissions,
        set: store_submissions,
        clear: clear_submissions
    },
    /* Profile Page Info */
    profile: {
        get: get_profile,
        set: store_profile,
        clear: clear_profile
    },
    clear_all: clear_all_data
}