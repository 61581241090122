import "@fortawesome/fontawesome-free/js/all.js";
import Alpine from 'alpinejs'
import {commons} from "commons.js";


// main init function
(function (){
    document.addEventListener("DOMContentLoaded", function(){
        Alpine.store('profile', null);
        Alpine.store('submissions', []);
        Alpine.store('currentLog',null);
        Alpine.start();
        window.Alpine = Alpine
    });
    commons.logging('index.js loaded!');
})();