window.playback = {
    playing: false,
};




function _toggle_play_audio(current_obj){
    try{
        const audio_obj = current_obj.getElementsByTagName('audio')[0];
        let icon_obj = current_obj.getElementsByTagName('span')[0];


        if(window.playback.playing){
            audio_obj.pause();
            audio_obj.currentTime = 0;
            window.playback.playing = false;
            icon_obj.innerHTML = '<i class="fa fa-play pointer"></i>'

        }else{
            window.playback.playing = true;
            icon_obj.innerHTML = '<i class="fas fa-stop"></i>'
            audio_obj.play();
        }
    }catch (e) {
        console.log(e);
    }
}

export const audio = {
    toggle_play: _toggle_play_audio,
};