import {commons} from "../commons";


function fetchSubmissions(){
    const notifArea = document.querySelector('#NotificationArea');
    commons.logging("==> Fetching submissions");
    const user = commons.store.user.get();
    if(!user){
        commons.store.submissions.set([])
    }else{
        commons.api.data.submissions.get(user.token).then(
            data => {
                commons.store.submissions.set(data.data);
                window.Alpine.store('submissions', data.data);
            }
        ).catch(
            err => {
                commons.store.submissions.set([]);
                const alertDiv = commons.createAlert( "Failed to fetch submissions !!", "alert-danger")
                notifArea.appendChild(alertDiv);
            }
        )
    }
}

function openLog(sub){
    commons.logging(`===> Fetching log ${sub.submission_id}`);
    window.Alpine.store('currentLog', null);
    const notifArea = document.querySelector('#NotificationArea');
    let body = "<pre class='prettyprint'><code>"
    const end_body = "</code></pre>"
    const user = commons.store.user.get();
    if(!user){
        console.log("what?");
        return 0;
    }

    commons.api.data.submissions.log(sub.submission_id, user.token).then(
        data => {
            for(const line of data.data){
                body += line
            }
            body += end_body
            window.Alpine.store('currentLog', {
                body: body,
                title: `Log of ${sub.submission_id}`
            });
        }
    ).catch(
        err => {
            console.log(err);
            alert("failed to fetch logs !!");
        }
    )
}

function submissionPageData() {

    return {
        fetchSubmissions: fetchSubmissions,
        openLog: openLog,
        isLoggedIn: window.commons.auth.isLoggedIn(),
        reload: () => { location.reload() }
    };
}

export const challenges = {
    submissionPageData: submissionPageData
}