configurations = {
    env: 'prod',
    isDev: false,
    allowGlobal: true,
    allowConsole: true,
    auth_cfg: {
        client_id: "zerospeech.com",
        client_secret: "fBhE914xVX2zcbuordzf/fjFdAKRIrcw+j7n4UE08yg="
    },
    api_host: "https://api.zerospeech.com",
    leaderboard_archive: "https://download.zerospeech.com/leaderboards",
    origin: `${window.location.origin}`,
    url_prefix: "",
    api_routes: {
        login: '/auth/login',
        logout: '/auth/logout',
        current_user: '/users',
        profile: '/users/profile',
        submissions: '/users/submissions',
        submission_logs: '/users/submissions/####/log'
    },
    api_pages: {
        sing_up: '/page/new-user',
        password_reset: '/page/request-password-update',
    },
    benchmarks: {
        "ABX-15": "tasks/task_1/results/#abx-15-leaderboard",
        "ABX-17": "tasks/task_1/results/#abx-17-leaderboard",
        "ABX-LS": "tasks/task_1/results/#abx-ls-leaderboard",
        "TDE-15": "tasks/task_2/results/#tde-15-leaderboard",
        "TDE-17": "tasks/task_2/results/#tde-17-leaderboard",
        "TTS0-19": "tasks/task_3/results/#tts0-19-leaderboard",
        "sLM-21": "tasks/task_4/results/#slm-21-leaderboard",
        "sLMProsody": "tasks/task_4/results/#slm-prosody"
    }
}


// export configurations globally
window.configurations = configurations;




