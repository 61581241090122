import '../config/config.js';
import {auth} from './commons/auth.js';
import {store} from "./commons/store.js";
import {api} from './commons/api.js';
import {challenges} from "./commons/challenges.js";
import {profile} from "./commons/profile.js";
import {audio} from "./commons/audio";


function logging (msg) {
    if(window.configurations.allowConsole)
        console.log(msg);
}

function alert_item(child, type){
    const alertDiv = document.createElement('div');

    /* add alert attributes */
    alertDiv.classList.add("alert");
    alertDiv.classList.add(type);
    alertDiv.classList.add("alert-dismissible");
    alertDiv.classList.add("fade");
    alertDiv.classList.add("show");
    /* alert role */
    alertDiv.setAttribute("role", "alert");

    /* Close Button */
    const closeButton = document.createElement('button');
    closeButton.setAttribute('type', "button");
    closeButton.setAttribute('data-dismiss', "alert");
    closeButton.setAttribute('aria-label', "Close");
    closeButton.classList.add('close');

    /* <span aria-hidden="true">&times;</span> */
    const innerButton = document.createElement('span');
    innerButton.setAttribute('aria-hidden', "true");
    innerButton.innerHTML = "&times;";

    // add inner to button
    closeButton.appendChild(innerButton);

    // append all items to div
    alertDiv.appendChild(child);
    alertDiv.appendChild(closeButton);

    return alertDiv;
}

function createAlert(text, type){
    // create label item
    const textItem = document.createElement('strong');
    textItem.innerText = text;

    return alert_item(textItem, type);
}

function subscribeToMailingList(email){
    const textItem = document.createElement('p');
    textItem.innerHTML = "You can subscribe to our mailing list by using the following link" +
        `<a href="mailto:${email}?subject=subscribe"> ${email} </a>`
    return alert_item(textItem, "alert-info")
}

export const commons = {
    api: api,
    auth: auth,
    store: store,
    challenges: challenges,
    profile: profile,
    audio: audio,
    logging: logging,
    createAlert: createAlert,
    subscribeToMailingList: subscribeToMailingList
}

// allow global items (for debugging in console)
if (window.configurations.allowGlobal) {
    window.commons = commons;
}


(function () {
    commons.logging('commons module loaded!');
})();