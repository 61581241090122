import { store } from './store.js';
import { api  } from './api.js';

const loadingComponent = '#GloablLoadingItem';

function _isLoggedIn() {
    const user = store.user.get();
    if(!user) return false;

    // check user object
    return user.hasOwnProperty('username') &&
        user.hasOwnProperty('token')
}

function _set_user(access_token) {
    api.auth.get_current_user(access_token).then(
        (response) => {
            const user = {
                username: response.data.username,
                email: response.data.email,
                verified: response.data.verified,
                token: access_token
            }
            // set values & reload
            store.user.set(user);
            location.reload();
        }
    ).catch(err => {
       console.log("something went bad");
       console.error(err);
    });
}

function _login(username, password){
    api.auth.create_session(username, password)
        .then(response => {
            _set_user(response.data.access_token)
        })
        .catch(
            err => {
                console.log(err);
            }
        )
}

function _fakeLogin(){
    const user = {
        username: "fakeGuy",
        email: "nowhere@mail.com",
        verified: true,
        token: "FAKE_TOKEN"
    }
    // set values & reload
    store.user.set(user);
    location.reload();
}

function _logout() {
    const user = store.user.get()
    if(user){
        api.auth.delete_session(user.token)
            .then(response => { console.log(response) });
    }
    store.clear_all();
    window.location = '/';
    location.reload();
}


function loginForm() {
    const notifArea = document.querySelector('#NotificationArea');
    const closeModalButton = document.querySelector('#loginModalCloseButton');

    return {
        formData: {
            login: '',
            password: '',
        },
        loading: false,
        submitData() {
            this.loading = true;
            api.auth.create_session(this.formData.login, this.formData.password)
                .then(response => {
                    console.log(response);
                    _set_user(response.data.access_token);
                })
                .catch(err => {
                    const err_notification = commons.createAlert('Failed to login into server !!', 'alert-danger');
                    notifArea.appendChild(err_notification);
                    this.formData.login = '';
                    this.formData.password = '';
                    this.loading = false;
                    closeModalButton.click();
                })
        }
    }
}

export const auth = {
    login: _login,
    isLoggedIn: _isLoggedIn,
    logout: _logout,
    loginForm: loginForm,
    fakeLogin: _fakeLogin,
};

