import axios from 'axios';

// axios.defaults.baseURL = window.configurations.api_host;

function urlBuilder(fn_name) {
    const root_api = window.configurations.api_host;
    if (window.configurations.api_routes.hasOwnProperty(fn_name)) {
        return `${root_api}${window.configurations.api_routes[fn_name]}`;
    } else if(window.configurations.api_pages.hasOwnProperty(fn_name)){
        return `${root_api}${window.configurations.api_pages[fn_name]}`;
    }
}

const API_URLS = {
    login: urlBuilder('login'),
    logout: urlBuilder('logout'),
    submission_logs: urlBuilder('submission_logs'),
    profile: urlBuilder('profile'),
    submissions: urlBuilder('submissions')
};

const API_PAGES = {
    sing_up: urlBuilder('sing_up'),
    password_reset: urlBuilder('password_reset'),
}

function test_api() {
    return axios.get('/')
        .finally(function () {
            console.log('thing finished no matter result');
        });
}

/* Get base info for the current user */
function get_current_user(token) {
    return axios.get(API_URLS.profile, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

/** Create a new login session */
function create_session(username, password) {
    const auth_cfg = window.configurations.auth_cfg;

    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    formData.append("client_id", auth_cfg.client_id);
    formData.append("client_secret", auth_cfg.client_secret);

    return axios.post(API_URLS.login, formData, {headers: {'content-type': 'application/x-www-form-urlencoded'}});
}

/** Clear current session */
function delete_session(token) {
    return axios.delete(API_URLS.logout, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

/** fetch profile data of current user */
function getProfileData(token) {
    // fixme this function is an alias because of an bad update of the api
    return get_current_user(token);
}

/** Update profile data of current user */
function updateProfileData(data, token) {
    return axios.post(API_URLS.profile, data, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

/** Fetch Submission Data */
function fetchSubmissions(token) {
    return axios.get(API_URLS.submissions,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

function fetchLogs(submission_id, token){
    const url = API_URLS.submission_logs.replace("####", submission_id);
    return axios.get(url,{
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
}

// module http
export const api = {
    auth: {
        create_session: create_session,
        delete_session: delete_session,
        get_current_user: get_current_user
    },
    data: {
        submissions: {
            get: fetchSubmissions,
            log: fetchLogs
        },
        profile: {
            get: getProfileData,
            set: updateProfileData
        }
    },
    test_api: test_api,
    urls: API_URLS,
    pages: API_PAGES
}
