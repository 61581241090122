import {commons} from "../commons";

function refreshProfileData () {
    commons.logging("==> Fetching profile data");
    const notifArea = document.querySelector('#NotificationArea');
    const user = commons.store.user.get();
    if(!user){
        commons.store.profile.set(null);
    }else{
        commons.api.data.profile.get(user.token).then(
            data => {
                window.Alpine.store('profile', data.data);
                commons.store.profile.set(data.data);
                console.log(data.data);
            }
        ).catch(
            err => {
                console.log(err)
                commons.store.profile.set({
                    username: user.username,
                    email: user.email
                });
                const alertDiv = commons.createAlert( "Failed to fetch profile information !!", "alert-danger")
                notifArea.appendChild(alertDiv);
            }
        )
    }
}

function submitChanges(data){
    const notifArea = document.querySelector('#NotificationArea');
    const user = commons.store.user.get();

    if(!user){
        return 0;
    }
    commons.api.data.profile.set(data, user.token).then(
        data => {
            const alertDiv = commons.createAlert( "Successfully updated information !!", "alert-success")
            notifArea.appendChild(alertDiv);
        }
    ).catch(
        err => {
            const alertDiv = commons.createAlert( "Failed to update information !!", "alert-danger")
            notifArea.appendChild(alertDiv);
        }
    )
}

function profilePageData() {
    return {
        isLoggedIn: commons.auth.isLoggedIn(),
        refreshProfileData: refreshProfileData,
        update_user_data: submitChanges
    }
}


export const profile = {
    profilePageData: profilePageData,
    fetch_user_data: refreshProfileData,
    update_user_data: submitChanges
}